
import { mapState } from 'vuex'

import LoginForm from '@/components/LoginForm'

export default {
  components: {
    LoginForm,
  },
  middleware: 'guest',
  data() {
    return {
      updateSuccess: !!this.$route.params.updateSuccess || false,
      doubleoptinConsent: !!this.$route.params.doubleoptinConsent || false,
      reorderCTANavigateToReviewAndPay: false,
    }
  },
  computed: {
    ...mapState(['sessionExpired', 'loginRedirectTo']),
  },
  async mounted() {
    this.reorderCTANavigateToReviewAndPay = await this.$store.dispatch(
      'split/getSplitTreatment',
      'reorderCTANavigateToReviewAndPaySplit',
    )
  },
  methods: {
    async loginSuccessful(stopIsLoading) {
      const reOrderId = this.$route.query?.redir?.replace?.('?refill=', '')
      const { fullPath } = this.$route
      const authRedirect = this.$auth.$storage.getUniversal('redirect')
      this.$auth.$storage.removeUniversal('redirect')

      if (reOrderId) {
        this.$log({
          name: 'ReorderTriggered',
          properties: { location: 'Login page loginSuccessful()', orderId: reOrderId, fullPath },
        })
        await this.$store.dispatch('order/loadReorderOrder', {
          orderId: reOrderId,
          sendUserToPayments: this.reorderCTANavigateToReviewAndPay,
        })
      } else if (this.loginRedirectTo) {
        this.$router.push(this.$getLink(this.loginRedirectTo))
      } else if (this.updateSuccess) {
        this.$router.push({ path: '/' })
      } else if (this.$nuxt.context?.from?.fullPath && this.$getLink('/login') !== this.$nuxt.context.from.fullPath) {
        this.$router.push(this.$getLink(this.$nuxt.context.from.fullPath))
      } else if (this.$route.query?._a) {
        this.$router.push({ path: '/', query: this.$route.query })
      } else if (authRedirect) {
        this.$router.push(authRedirect)
      } else {
        this.$router.push(this.$getLink('/account'))
      }
      stopIsLoading()

      this.$store.commit('SET_LOGIN_REDIRECT_TO', '')
    },
  },
}
